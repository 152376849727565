import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Flex, Button, Box } from 'rebass';
import { useTranslation } from 'react-i18next';
import scriptLoader from 'react-async-script-loader';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete';
import ScaleLoader from 'react-spinners/ScaleLoader';
// If you want to use the provided css
import 'react-google-places-autocomplete/dist/assets/index.css';

import Alert from '../Alert';
import { Input } from '../Form';
import { googlePlacesHandler, validatePasswordPolicy } from '../../utils';
import PasswordPopOver from '../PasswordPopOver';

const AccountCorpDetailsForm = ({
  message,
  account,
  padding = '25%',
  loadingIndicator,
  isAccountPage,
  alertType,
}) => {
  const { register, errors, getValues } = useFormContext();
  const initValues = account
    ? account
    : {
        password: null,
        contact: {
          fullname: null,
          address: {
            street: '',
            city: null,
            zipCode: null,
          },
          phone: null,
          email: null,
        },
      };

  const [constactForm, setContactForm] = useState(initValues);
  const [showTooltip, setShowTooltip] = useState(false);
  const { t } = useTranslation();

  const onGoogleSelect = async data => {
    const googleRes = await geocodeByPlaceId(data.place_id);
    const { gAddress, streetAddr } = googlePlacesHandler(googleRes);
    setContactForm({
      password: constactForm.password,
      contact: {
        fullname: constactForm.contact.fullname,
        address: {
          street: streetAddr,
          city: gAddress.locality ? `${gAddress.locality}` : '',
          zipCode: gAddress.postal_code
            ? `${gAddress.postal_code.replace(' ', '')}`
            : '',
        },
        phone: constactForm.contact.phone,
        email: constactForm.contact.email,
      },
    });
  };
  return (
    <Flex
      px={[0, padding, padding]}
      flexDirection="column"
      flexWrap="wrap"
      pt="40px"
    >
      <Alert message={message} type={alertType} />

      <Input
        style={isAccountPage ? { display: 'none' } : {}}
        title={isAccountPage ? '' : t('my_boxit.account_details.email')}
        name="email"
        defaultValue={constactForm.contact.email}
        disabled
        ref={register({
          required: true,
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            message: t('validation.invalid_email_address'),
          },
        })}
      />
      <PasswordPopOver showTooltip={showTooltip}>
        <Input
          title={t('my_boxit.account_details.password')}
          name="password"
          type="password"
          onFocus={() => {
            setShowTooltip(true);
          }}
          onBlur={() => {
            setShowTooltip(false);
          }}
          defaultValue={constactForm.password}
          ref={register({
            required: true,
            validate: {
              passwordPolicy: value => {
                const validation = validatePasswordPolicy(value);
                return validation.errorMessage
                  ? t(`validation.password.${validation.errorMessage}`)
                  : true;
              },
              passwordMisMatch: value => {
                const formValues = getValues();
                return (
                  formValues['confirmPassword'] === value ||
                  t('validation.passwordMisMatch')
                );
              },
            },
          })}
        />
      </PasswordPopOver>

      <Input
        title={t('my_boxit.account_details.confirmPassword')}
        name="confirmPassword"
        type="password"
        defaultValue={constactForm.password}
        ref={register({
          required: true,
          validate: {
            passwordMisMatch: value => {
              const formValues = getValues();
              return (
                formValues['password'] === value ||
                t('validation.passwordMisMatch')
              );
            },
          },
        })}
      />

      <Input
        title={t('my_boxit.account_details.companyName')}
        name="companyName"
        disabled
        defaultValue={constactForm.companyInfo.companyName}
        ref={register({ required: true })}
      />
      <Input
        title={t('my_boxit.account_details.vat')}
        name="vat"
        disabled
        defaultValue={constactForm.companyInfo.vat}
        ref={register({ required: true })}
      />
      <Input
        title={t('my_boxit.account_details.taxOffice')}
        name="taxOffice"
        disabled
        defaultValue={constactForm.companyInfo.taxOffice}
        ref={register({ required: true })}
      />
      <Input
        title={t('my_boxit.account_details.POBox')}
        name="POBox"
        disabled
        defaultValue={constactForm.companyInfo.POBox}
      />
      <Input
        title={t('my_boxit.account_details.fullname')}
        name="fullname"
        disabled
        defaultValue={constactForm.contact.fullname}
        ref={register({ required: true })}
      />
      <GooglePlacesAutocomplete
        disabled
        initialValue={constactForm.contact.address.street}
        placeholder=""
        autocompletionRequest={{
          componentRestrictions: {
            country: ['gr'],
          },
        }}
        onSelect={onGoogleSelect}
        renderInput={props => (
          <div className="custom-wrapper1">
            <Input
              title={t('my_boxit.account_details.address')}
              name="street"
              disabled
              ref={register({ required: true })}
              // Custom properties
              {...props}
            />
          </div>
        )}
      />
      <Input
        title={t('my_boxit.account_details.city')}
        name="city"
        disabled
        defaultValue={constactForm.contact.address.city}
        ref={register({ required: true })}
      />
      <Input
        title={t('my_boxit.account_details.zip')}
        name="zipCode"
        disabled
        defaultValue={constactForm.contact.address.zipCode}
        ref={register({ required: true })}
      />
      <Input
        title={t('my_boxit.account_details.phone')}
        name="phone"
        disabled
        defaultValue={constactForm.contact.phone}
        ref={register({ required: true })}
      />
      {!loadingIndicator && (
        <Button
          alignSelf="center"
          width={[1, 1 / 2, 1 / 2]}
          px="73px"
          py="8px"
          type="submit"
          fontSize="14px"
          fontWeight="bold"
          bg="blues.peacock"
          css={{
            lineHeight: 1.5,
            letterSpacing: '2.34px',
            borderRadius: '4px',
            ':hover': {
              cursor: 'pointer',
            },
          }}
        >
          {t('my_boxit.account_details.button')}
        </Button>
      )}
      {loadingIndicator && (
        <Box textAlign="center" width={['100%']}>
          <ScaleLoader
            css={{}}
            size={40}
            color={'#01549b'}
            loading={loadingIndicator}
          />
        </Box>
      )}
    </Flex>
  );
};

class LoadAccountDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = { shouldRender: false };
  }

  componentWillReceiveProps({ isScriptLoaded, isScriptLoadSucceed }) {
    if (isScriptLoaded && !this.props.isScriptLoaded) {
      // load finished
      if (isScriptLoadSucceed) {
        this.setState({ shouldRender: true });
      }
    }
  }

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;
    if (isScriptLoaded && isScriptLoadSucceed) {
      this.setState({ shouldRender: true });
    }
  }

  render() {
    if (!this.state.shouldRender) {
      return null;
    }
    return <AccountCorpDetailsForm {...this.props} />;
  }
}

export default scriptLoader([
  'https://maps.googleapis.com/maps/api/js?key=AIzaSyDzrA178KC8bCxnI7iogrDZmgERZ7b1hYI&libraries=places',
])(LoadAccountDetails);
