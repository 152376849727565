import React from 'react';
import { Box, Text, Flex } from 'rebass';
import { useTranslation } from 'react-i18next';
import Popover, {ArrowContainer} from 'react-tiny-popover'

const PasswordPopOverComponent= ({children, showTooltip})=> {
    const { t } = useTranslation();

return(
    <Popover
        isOpen={showTooltip}
        padding={0}
        position={['right','top']} // preferred position
        content={({ position, targetRect, popoverRect }) => (
          <Box>
            <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                position={position}
                targetRect={targetRect}
                popoverRect={popoverRect}
                arrowColor={'#0B5190'}
                arrowSize={10}
                arrowStyle={{ opacity: 1}}
            >
                <Box
                    style={{ 
                      backgroundColor: '#F4D448', 
                      opacity: 1,
                      border: "1px solid #0B5190",
                      borderRadius:"6px"
                    }}
                    px={"10px"}
                    py={"10px"}
                >
                  <Flex flexDirection="column" color={"#0B5190"} fontSize="12px">
                    <Flex>
                      <Text>{t('passwordPopover.title')}</Text>
                    </Flex>
                    <Flex  textAlign="center">
                      <Box textAlign="center" my="auto" mr="5px">
                        <Box width="10px" height="10px" bg="#0B5190" style={{ borderRadius:"10px"}}></Box>
                      </Box>
                      <Text>{t('passwordPopover.length')}</Text>
                    </Flex>
                    <Flex>
                      <Box textAlign="center" my="auto"  mr="5px">
                        <Box width="10px" height="10px" bg="#0B5190" style={{ borderRadius:"10px"}}></Box>
                      </Box>
                      <Text>{t('passwordPopover.containSymbol')}</Text>
                    </Flex>
                    <Flex>
                      <Box textAlign="center" my="auto"  mr="5px">
                        <Box width="10px" height="10px" bg="#0B5190" style={{ borderRadius:"10px"}}></Box>
                      </Box>
                      <Text>{t('passwordPopover.containNumber')}</Text>
                    </Flex>
                    <Flex>
                      <Box textAlign="center" my="auto"  mr="5px">
                        <Box width="10px" height="10px" bg="#0B5190" style={{ borderRadius:"10px"}}></Box>
                      </Box>
                      <Text>{t('passwordPopover.containSmall')}</Text>
                      </Flex>
                    <Flex>
                      <Box textAlign="center" my="auto"  mr="5px">
                        <Box width="10px" height="10px" bg="#0B5190" style={{ borderRadius:"10px"}}></Box>
                      </Box>
                      <Text>{t('passwordPopover.containCaps')}</Text>
                    </Flex>
                  </Flex>
                </Box>
            </ArrowContainer>
          </Box>
      )}
    >
    {children}
    </Popover>
    )
}

export default PasswordPopOverComponent;