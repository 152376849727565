import React, { useState, useEffect } from 'react';
import { Flex, Box, Heading } from 'rebass';
import { useFormContext, Controller } from 'react-hook-form';
import { Input } from '../Form';
import { useTranslation } from 'react-i18next';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import 'react-google-places-autocomplete/dist/assets/index.css';

import { googlePlacesHandler } from '../../utils'

const CustomInput = (props) => {
  const { t } = useTranslation();
  const { register, unregister, setValue, name } = useFormContext();
  useEffect(() => {
    register({ name: 'street'}, { required: true });
    return () => unregister(name);
  }, [name, register, unregister]);
  
  if(props.value){
    setValue('street', props.value)
  }
 
  return(
    <div className="custom-wrapper">
        <Input
          name="street"
          title={t('my_boxit.create_box_modal.enter_recipient.address')}
          {...props}
        />
    </div>
  )
};

const EnterRecipient = ({recipientData}) => {

  const { register, formState , reset } = useFormContext();

    // Read the formState before render to subscribe the form state through Proxy
  const { dirty, isSubmitting, touched, submitCount } = formState;
 
  const initValues =  recipientData ? {
    fullname: recipientData.fullname || null,
    address: {
      street: recipientData.address.street || "",
      city: recipientData.address.city || null,
      zipCode: recipientData.address.zipCode || null ,
    },
    phone: recipientData.phone || null,
    email: recipientData.email || null,
    details: recipientData.details || null
  } : {
    fullname: null,
    address: {
      street: "",
      city: null,
      zipCode: null,
    },
    phone: null,
    email:  null,
    details: null,
  }
  const [recipient, setRecipient] = useState(initValues)
  const { t } = useTranslation();

  const onGoogleSelect = async(data)=> {
    const googleRes = await geocodeByPlaceId(data.place_id);
    const {gAddress, streetAddr} = googlePlacesHandler(googleRes);
    setRecipient({
      fullname: recipient.fullname,
      address: {
          street: streetAddr,
          city: (gAddress.locality || gAddress.administrative_area_level_3) ? `${gAddress.locality || gAddress.administrative_area_level_3}`: null,
          zipCode: (gAddress.postal_code) ? `${gAddress.postal_code.replace(" ", "")}`:null,
      },
      phone: recipient.phone,
      email: recipient.email,
      details: recipient.details
    })
  }

  return (
    <Box width={1}>
      <Heading
        fontSize={['12px', '20px', '25px']}
        fontWeight="normal"
        lineHeight="1.04"
        letterSpacing="0.2px"
        color="blues.peacock"
      >
        {t('my_boxit.create_box_modal.enter_recipient.header')}
      </Heading>

      <Flex py="15px" flexDirection="row" flexWrap="wrap" alignItems="top">
        <Box width={[1, 1 / 2, 1 / 2]} pr={[0, '28px', '28px']}>
          <Input
            title={t('my_boxit.create_box_modal.enter_recipient.fullname')}
            name="fullname"
            defaultValue={recipient.fullname}
            ref={register({ required: true })}
          />
           <GooglePlacesAutocomplete
            initialValue={recipient.address.street}
            placeholder=""
            autocompletionRequest={{
              componentRestrictions: {
                country: ['gr']
                }
              }}
              onSelect={onGoogleSelect}
              renderInput={(props) => (
                <CustomInput {...props} />
              )}
           />
          <Input
            title={t('my_boxit.create_box_modal.enter_recipient.zip')}
            name="zipCode"
            defaultValue={recipient.address.zipCode}
            ref={register({ required: true })}
          />
          <Input
            title={t('my_boxit.create_box_modal.enter_recipient.city')}
            name="city"
            defaultValue={recipient.address.city}
            ref={register({ required: true })}
          />
        </Box>
        <Box width={[1, 1 / 2, 1 / 2]}>
          <Input
            title={t('my_boxit.create_box_modal.enter_recipient.phone')}
            name="phone"
            defaultValue={recipient.phone}
            ref={register({ required: true,
              pattern: {
                value: /^[0-9]{10}$/i,
                message: t('validation.invalidPhoneNumber'),
              }, })}
          />
          <Input
            title={t('my_boxit.create_box_modal.enter_recipient.email')}
            name="email"
            defaultValue={recipient.email}
            ref={register({
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: t('validation.invalid_email_address'),
              },
            })}
          />
          <Input
            title={t('my_boxit.create_box_modal.enter_recipient.details')}
            defaultValue={recipient.details}
            name="details"
            ref={register({ required: false })}
          />
        </Box>
      </Flex>
    </Box>
  );
}

export default EnterRecipient;
