import React, { useState } from 'react';
import { useIdentityContext } from 'react-netlify-identity';
import { useMutation } from '@apollo/react-hooks';
//import useForm, { useFormContext } from 'react-hook-form';
import { gql } from 'apollo-boost';
import { useTranslation } from 'react-i18next';
import scriptLoader from 'react-async-script-loader';

import BoxitModal from '../../components/Modal';
import SelectBoxSize from './SelectBoxSize';
import SelectDeliveryPoint from './SelectDeliveryPoint';
import EnterRecipient from './EnterRecipient';
import SelectPickupPoint from './SelectPickupPoint';
import BoxStatusInfo from '../../components/BoxStatusInfo';

import { MY_BOXES } from '../MyBoxit/Sending';

import { MY_PENDING_BOXES } from '../CheckoutInfoHeader/CheckoutInfoHeader';
import dayjs from 'dayjs';

const CREATE_BOX = gql`
  mutation CreateBox(
    $size: BoxSize!
    $accountId: ID!
    $shippingCost: Float!
    $pickupPointId: ID
    $deliveryPointId: ID!
    $recipientFullname: String!
    $recipientPhone: String!
    $recipientEmail: String!
    $recipientAddrSteet: String!
    $recipientAddrZipCode: String!
    $recipientAddrCity: String!
    $deliveryAtHome: Boolean!
    $comment: String
    $createdAt: Long
  ) {
    createBox(
      data: {
        size: $size
        status: pending
        isDeleted: false
        shippingCost: $shippingCost
        account: { connect: $accountId }
        pickupPoint: { connect: $pickupPointId }
        deliveryPoint: { connect: $deliveryPointId }
        createdAt: $createdAt
        deliveryAtHome: $deliveryAtHome
        comment: $comment
        recipient: {
          fullname: $recipientFullname
          phone: $recipientPhone
          email: $recipientEmail
          address: {
            street: $recipientAddrSteet
            zipCode: $recipientAddrZipCode
            city: $recipientAddrCity
          }
        }
      }
    ) {
      __typename
      _id
      _ts
      size
      status
      recipient {
        fullname
        address {
          street
          zipCode
          city
        }
        phone
        email
      }
      deliveryPoint {
        name
        contact {
          fullname
          address {
            street
            zipCode
            city
          }
          phone
        }
      }
      pickupPoint {
        name
        contact {
          fullname
          address {
            street
            zipCode
            city
          }
          phone
        }
      }
      history {
        status
        _ts
        _id
      }
      comment
      createdAt
      deliveryAtHome
      shippingCost
    }
  }
`;

const CREATE_BOX_HOME_DELIVERY = gql`
  mutation CreateBox(
    $size: BoxSize!
    $accountId: ID!
    $shippingCost: Float!
    $deliveryPointId: ID!
    $recipientFullname: String!
    $recipientPhone: String!
    $recipientEmail: String!
    $recipientAddrSteet: String!
    $recipientAddrZipCode: String!
    $recipientAddrCity: String!
    $deliveryAtHome: Boolean!
    $comment: String
    $createdAt: Long
  ) {
    createBox(
      data: {
        size: $size
        status: pending
        isDeleted: false
        shippingCost: $shippingCost
        account: { connect: $accountId }
        deliveryPoint: { connect: $deliveryPointId }
        createdAt: $createdAt
        deliveryAtHome: $deliveryAtHome
        comment: $comment
        recipient: {
          fullname: $recipientFullname
          phone: $recipientPhone
          email: $recipientEmail
          address: {
            street: $recipientAddrSteet
            zipCode: $recipientAddrZipCode
            city: $recipientAddrCity
          }
        }
      }
    ) {
      __typename
      _id
      _ts
      size
      status
      recipient {
        fullname
        address {
          street
          zipCode
          city
        }
        phone
        email
      }
      deliveryPoint {
        name
        contact {
          fullname
          address {
            street
            zipCode
            city
          }
          phone
        }
      }
      pickupPoint {
        name
        contact {
          fullname
          address {
            street
            zipCode
            city
          }
          phone
        }
      }
      history {
        status
        _ts
        _id
      }
      comment
      createdAt
      deliveryAtHome
      shippingCost
    }
  }
`;

const BASE_COST = 3.25;
const DELIVERY_AT_HOME_COST = 5.0;

const CreateBoxModal = ({ onCloseButtonClick, ...props }) => {
  const [activeStep, setActiveStep] = useState(1);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [data, setData] = useState({});

  const { user } = useIdentityContext();

  const Mutation = data.deliveryAtHome ? CREATE_BOX_HOME_DELIVERY : CREATE_BOX;

  const [createBox] = useMutation(Mutation, {
    update(
      cache,
      {
        data: { createBox },
      }
    ) {
      const queryVars = {
        accountId: user.user_metadata.aid,
        cursorId: null,
      };

      const { boxesByAccountIdNotDeletedAsc } = cache.readQuery({
        query: MY_BOXES,
        variables: queryVars,
      });

      cache.writeQuery({
        query: MY_BOXES,
        variables: queryVars,
        data: {
          boxesByAccountIdNotDeletedAsc: {
            ...boxesByAccountIdNotDeletedAsc,
            data: [createBox].concat(boxesByAccountIdNotDeletedAsc.data),
            before: boxesByAccountIdNotDeletedAsc.before,
            after: boxesByAccountIdNotDeletedAsc.after,
            __typename: boxesByAccountIdNotDeletedAsc.__typename,
          },
        },
      });

      const { findBoxByAccountNStatusNotDeletedRev } = cache.readQuery({
        query: MY_PENDING_BOXES,
        variables: {
          accountId: user.user_metadata.aid,
        },
      });

      cache.writeQuery({
        query: MY_PENDING_BOXES,
        variables: {
          accountId: user.user_metadata.aid,
        },
        data: {
          findBoxByAccountNStatusNotDeletedRev: {
            ...findBoxByAccountNStatusNotDeletedRev,
            data: [createBox].concat(findBoxByAccountNStatusNotDeletedRev.data),
            before: findBoxByAccountNStatusNotDeletedRev.before,
            after: findBoxByAccountNStatusNotDeletedRev.after,
            __typename: findBoxByAccountNStatusNotDeletedRev.__typename,
          },
        },
      });
    },
    onError: error => {
      console.error(`Box creation failed! ${JSON.stringify(error)}`);
      // setAlertMsg('Box creation failed!');
      // const errMap = {
      //   'instance not unique': 'Email is already registered.',
      // };
      // error.graphQLErrors.map(err => {
      //   const msg = errMap[err.extensions.code];
      //   if (msg) {
      //     setAlertMsg(msg);
      //   }
      // });
      setDisableSubmitButton(false);
    },
    onCompleted: async data => {
      setActiveStep(1);
      setData({});
      onCloseButtonClick();
      setDisableSubmitButton(false);
    },
  });

  const clearData = () => {
    setData({});
    setActiveStep(1);
    setDisableSubmitButton(false);
  };

  const handleBoxSelection = boxSize => {
    console.log('asdadad');
    setData({
      ...data,
      boxSize,
    });
  };

  const handleRecipientChange = recipientData => {
    console.log('recipientData', recipientData);
    setData({
      ...data,
      recipient: {
        fullname: recipientData.fullname,
        address: {
          street: recipientData.street,
          city: recipientData.city,
          zipCode: recipientData.zipCode,
        },
        phone: recipientData.phone,
        email: recipientData.email,
        details: recipientData.details,
      },
    });
  };

  const handleDeliveryPointSelection = deliveryPoint => {
    setData({
      ...data,
      deliveryPoint,
    });
  };

  const handlePickupPointSelection = pickupPoint => {
    //console.log(pickupPoint)
    if (pickupPoint._id) {
      setData({
        ...data,
        pickupPoint,
        deliveryAtHome: false,
      });
    } else {
      const nData = data;
      delete nData.pickupPoint;
      setData({
        ...nData,
        deliveryAtHome: true,
        shippingCost: pickupPoint.additionalCost,
      });
    }
  };

  //console.log('data', data);

  const { t } = useTranslation();

  const validateSteps = (data, step) => {
    let validation = false;
    switch (step) {
      case 1:
        if (data.boxSize) {
          validation = true;
        }
        break;
      case 2:
        if (data.deliveryPoint) {
          validation = true;
        }
        break;
      case 3:
        if (data.recipient) {
          validation = true;
        }
        break;
      case 4:
        if (data.pickupPoint || data.deliveryAtHome) {
          validation = true;
        }
        break;
      case 5:
        validation = true;
        break;
    }

    console.log('validation', validation);

    return validation;
  };

  return (
    <BoxitModal
      {...props}
      title={t('my_boxit.create_box_modal.title')}
      subtitle={t('my_boxit.create_box_modal.subtitle')}
      width={['100%', '100%', '1120px']}
      minHeight={['100%', '100%', '520px']}
      activeStep={activeStep}
      onPreviousButtonClick={e => {
        if (activeStep !== 1) {
          setActiveStep(activeStep - 1);
        }
      }}
      onNextButtonClick={formData => {
        console.log('onNextButtonClick', activeStep);
        switch (activeStep) {
          case 3:
            handleRecipientChange(formData);
            setActiveStep(activeStep + 1);
            break;
          default:
            if (validateSteps(data, activeStep)) {
              setActiveStep(activeStep + 1);
            }
            break;
        }
      }}
      disableSubmitButton={disableSubmitButton}
      onFinishButtonClick={e => {
        console.log('onFinishButtonClick');
        const {
          boxSize,
          pickupPoint,
          deliveryPoint,
          recipient,
          deliveryAtHome,
          shippingCost,
          comment,
        } = data;
        setDisableSubmitButton(true);
        const variables = {
          size: boxSize,
          accountId: user.user_metadata.aid,
          deliveryPointId: deliveryPoint._id,
          recipientFullname: recipient.fullname,
          recipientPhone: recipient.phone,
          recipientEmail: recipient.email,
          recipientAddrSteet: recipient.address.street,
          recipientAddrZipCode: recipient.address.zipCode,
          recipientAddrCity: recipient.address.city,
          deliveryAtHome,
          comment: recipient.details,
          createdAt: dayjs().valueOf(), // (milliseconds)
        };
        if (deliveryAtHome) {
          variables.shippingCost = BASE_COST + parseFloat(shippingCost);
        } else {
          variables.pickupPointId = pickupPoint._id;
          variables.shippingCost =
            BASE_COST + parseFloat(pickupPoint.additionalCost);
        }

        createBox({
          variables,
        });
      }}
      onCloseButtonClick={e => {
        clearData();
        onCloseButtonClick(e);
      }}
      onBackgroundClick={e => {
        e.preventDefault();
      }}
      onEscapeKeydown={e => {
        e.preventDefault();
      }}
      wizardSteps={t('my_boxit.create_box_modal.steps', {
        returnObjects: true,
      })}
    >
      {(() => {
        switch (activeStep) {
          case 1:
            return (
              <SelectBoxSize
                selectedBox={data.boxSize}
                onBoxSelection={handleBoxSelection}
              />
            );
          case 2:
            return (
              <SelectDeliveryPoint
                onMarkerSelected={handleDeliveryPointSelection}
                selectedMarker={data.deliveryPoint}
              />
            );
          case 3:
            return <EnterRecipient recipientData={data.recipient} />; //TODO pass Data
          case 4:
            return (
              <SelectPickupPoint
                recipient={data.recipient}
                onMarkerSelected={handlePickupPointSelection}
              />
            );
          case 5:
            return <BoxStatusInfo boxData={data} />;
          default:
            return null;
        }
      })()}
    </BoxitModal>
  );
};

class LoadCreateBoxModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { shouldRender: false };
  }

  componentWillReceiveProps({ isScriptLoaded, isScriptLoadSucceed }) {
    if (isScriptLoaded && !this.props.isScriptLoaded) {
      // load finished
      if (isScriptLoadSucceed) {
        this.setState({ shouldRender: true });
      }
    }
  }

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;
    if (isScriptLoaded && isScriptLoadSucceed) {
      this.setState({ shouldRender: true });
    }
  }

  render() {
    if (!this.state.shouldRender) {
      return null;
    }
    return <CreateBoxModal {...this.props} />;
  }
}

export default scriptLoader([
  'https://maps.googleapis.com/maps/api/js?key=AIzaSyDzrA178KC8bCxnI7iogrDZmgERZ7b1hYI&libraries=places',
])(LoadCreateBoxModal);
