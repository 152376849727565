import React from 'react';
import { Router } from '@reach/router';
import { Box } from 'rebass';
import PrivateRoute from '../../components/privateRoute';
import MyBoxitLayout from './_layout';
import {
  MyBoxitSummary,
  MyBoxitSending,
  MyBoxitAccountDetails,
} from '../../components/MyBoxit';

const isBrowser = typeof window !== 'undefined';

const locales = require('../../locales');
const localizedPrefix = lang =>
  locales[lang].default ? '' : `/${locales[lang].path}`;

const MyBoxitRouting = props => {
  if (!isBrowser) return null;

  const locale = props.pageContext.locale;
  return (
    <MyBoxitLayout {...props}>
      <Box width={[1, 1, 2.18 / 3.18]} ml={['0', '0', '15px']}>
        <Router>
          <PrivateRoute
            path={`/${localizedPrefix(locale)}/my_boxit/`}
            component={MyBoxitSummary}
          />
          <PrivateRoute
            path={`/${localizedPrefix(locale)}/my_boxit/summary`}
            component={MyBoxitSummary}
          />
          <PrivateRoute
            path={`/${localizedPrefix(locale)}/my_boxit/sending`}
            component={MyBoxitSending}
          />
          <PrivateRoute
            path={`/${localizedPrefix(locale)}/my_boxit/account`}
            component={MyBoxitAccountDetails}
          />
        </Router>
      </Box>
    </MyBoxitLayout>
  );
};
export default MyBoxitRouting;
