import React from 'react';
import { Flex, Box, Card, Text, Heading, Image } from 'rebass';
import { useTranslation } from 'react-i18next';

import { Hide } from '../hide';

import PackingNo2Icon from '../../icons/packing-no2.svg';
import PackingNo5Icon from '../../icons/packing-no5.svg';
import PackingNo10Icon from '../../icons/packing-no10.svg';
import PackingNo15Icon from '../../icons/packing-no15.svg';

const BoxSizeChoice = ({
  imgSrc,
  label,
  value,
  selected,
  description,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <Box {...rest} width={[1, 'auto', 'auto']}>
      <Flex flexDirection={['row', 'row', 'column']} mr={['0', '10px', '10px']}>
        <Card
          width={['1/2', 'auto', 'auto']}
          borderRadius="10px"
          bg="blues.veryLight"
          px={['20px', '30px', '42px']}
          py={['35px', '50px', '68px']}
          css={{ maxHeight: '220px' }}
        >
          <Image width={['70px', 'auto', 'auto']} src={imgSrc} />
        </Card>

        <Box pt="4px" textAlign="center" display={['none', 'block', 'block']}>
          <label>
            <input type="radio" value={value} checked={selected} readOnly />
            <Text
              as="span"
              pl="10px"
              fontSize="14px"
              fontWeight="600"
              color="marine"
            >
              {label}
            </Text>
          </label>
        </Box>

        <Hide mobile>
          <Text
            fontSize="12px"
            textAlign="center"
            lineHeight="1.86"
            color="marine"
          >
            {description}
          </Text>
          <Box
            display={['none', 'block', 'block']}
            mt="8px"
            pt="9px"
            css={{
              borderTop: '1px solid rgba(1, 84, 155, 0.25)',
            }}
          >
            <Text
              fontSize="12px"
              color="marine"
              lineHeight="1.33"
              letterSpacing="0.56px"
            >
              {t('my_boxit.create_box_modal.select_box_size.dimensions')}
              {t(`my_boxit.create_box_modal.select_box_size.dimension_labels.${value}`)}
            </Text>
            <Text
              fontSize="12px"
              color="marine"
              lineHeight="1.33"
              letterSpacing="0.56px"
            >
              {t('my_boxit.create_box_modal.select_box_size.diagonal')}
              {t(`my_boxit.create_box_modal.select_box_size.diagonal_labels.${value}`)}
            </Text>
           </Box>
        </Hide>
        <Flex
          pl="8px"
          flexDirection="column"
          display={['block', 'none', 'none']}
        >
          <Text
            pb="5px"
            fontSize="12px"
            textAlign="center"
            lineHeight="1.86"
            color="marine"
          >
            {description}
          </Text>
          <Text
            fontSize="12px"
            color="marine"
            lineHeight="1.33"
            letterSpacing="0.56px"
          >
            {t('my_boxit.create_box_modal.select_box_size.dimensions')}
            {t(`my_boxit.create_box_modal.select_box_size.dimension_labels.${value}`)}
          </Text>
          <Text
            fontSize="12px"
            color="marine"
            lineHeight="1.33"
            letterSpacing="0.56px"
          >
            {t('my_boxit.create_box_modal.select_box_size.diagonal')}
            {t(`my_boxit.create_box_modal.select_box_size.diagonal_labels.${value}`)}
          </Text>
        </Flex>
      </Flex>
      <Box
        pt="16px"
        mb="16px"
        textAlign="center"
        display={['block', 'none', 'none']}
        css={{
          borderBottom: '1px solid rgba(1, 84, 155, 0.25)',
        }}
      >
        <label>
          <input type="radio" value={value} checked={selected} readOnly />
          <Text
            as="span"
            pl="10px"
            fontSize="14px"
            fontWeight="600"
            color="marine"
          >
            {label}
          </Text>
        </label>
      </Box>
    </Box>
  );
};

const SelectBoxSize = ({ selectedBox, onBoxSelection }) => {
  const { t } = useTranslation();

  return (
    <Box width={1}>
      <Heading
        fontSize={['12px', '20px', '25px']}
        fontWeight="normal"
        lineHeight="1.04"
        letterSpacing="0.2px"
        color="blues.peacock"
      >
        {t('my_boxit.create_box_modal.select_box_size.header')}
      </Heading>
      <Text pt="4px" fontSize="12px" color="blues.peacock">
        {t('my_boxit.create_box_modal.select_box_size.description')}
      </Text>
      <Flex py="15px" flexDirection="row" flexWrap="wrap" alignItems="top">
        <BoxSizeChoice
          onClick={() => onBoxSelection('no_2')}
          imgSrc={PackingNo2Icon}
          label={t('my_boxit.create_box_modal.select_box_size.sizes.0.label')}
          value="no_2"
          selected={selectedBox === 'no_2'}
          description={t(
            'my_boxit.create_box_modal.select_box_size.sizes.0.description'
          )}
        />
        <BoxSizeChoice
          onClick={() => onBoxSelection('no_5')}
          imgSrc={PackingNo5Icon}
          label={t('my_boxit.create_box_modal.select_box_size.sizes.1.label')}
          value="no_5"
          selected={selectedBox === 'no_5'}
          description={t(
            'my_boxit.create_box_modal.select_box_size.sizes.1.description'
          )}
        />
        <BoxSizeChoice
          onClick={() => onBoxSelection('no_10')}
          imgSrc={PackingNo10Icon}
          label={t('my_boxit.create_box_modal.select_box_size.sizes.2.label')}
          value="no_10"
          selected={selectedBox === 'no_10'}
          description={t(
            'my_boxit.create_box_modal.select_box_size.sizes.2.description'
          )}
        />
        <BoxSizeChoice
          onClick={() => onBoxSelection('no_15')}
          imgSrc={PackingNo15Icon}
          label={t('my_boxit.create_box_modal.select_box_size.sizes.3.label')}
          value="no_15"
          selected={selectedBox === 'no_15'}
          description={t(
            'my_boxit.create_box_modal.select_box_size.sizes.3.description'
          )}
        />
      </Flex>
    </Box>
  );
};

export default SelectBoxSize;
