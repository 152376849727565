import React, { useState } from 'react';
import { Flex, Box, Button, Text, Heading } from 'rebass';
import { gql } from 'apollo-boost';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { useIdentityContext } from 'react-netlify-identity';

import MyBoxitList from '../MyBoxitList';
import CreateBoxModal from '../CreateBoxModal';

import CheckoutInfoHeader from '../CheckoutInfoHeader';

const SendingModalButton = ({refetchPending}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  return (
    <>
      <Box
        ml="auto"
        mt={['20px', '0', '0']}
        width={[1, 1 / 2, 1 / 2]}
        css={{
          textAlign: 'end',
        }}
      >
        <Button
          borderRadius="4px"
          py="10px"
          px="28px"
          width={['100%', 'auto', 'auto']}
          fontWeight="bold"
          textAlign="center"
          bg="blues.peacock"
          onClick={() => setOpen(true)}
          css={{
            ':hover': {
              cursor: 'pointer',
            },
            fontSize: '14px',
            lineHeight: '1.5',
            letterSpacing: '2.34px',
          }}
        >
          {t('my_boxit.sending.create_new_box')}
        </Button>
      </Box>
      <CreateBoxModal
        isOpen={open}
        onBackgroundClick={() => setOpen(false)}
        onEscapeKeydown={() => setOpen(false)}
        onCloseButtonClick={() => setOpen(false)}
      />
    </>
  );
};

const MyBoxitListHeader = ({refetchPending}) => {
  const { t } = useTranslation();

  return (
    <Flex width={1} flexDirection="row" flexWrap="wrap">
      <Box width={[1, 1 / 2, 1 / 2]}>
        <Heading
          fontSize="25px"
          lineHeight="1.04"
          fontWeight="normal"
          color="blues.peacock"
        >
          {t('my_boxit.sending.sending')}
        </Heading>
        {/* <Text>Tue, 12 Feb 2018</Text> */}
      </Box>
      <SendingModalButton refetchPending={refetchPending}  />
    </Flex>
  );
};

export const MY_BOXES = gql`
  query FindAccountBoxes($accountId: ID!, $cursorId: String) {
    boxesByAccountIdNotDeletedAsc(accountId: $accountId, _size: 5, _cursor: $cursorId)
    {
    data {
        _id
        _ts
        size
        status
        createdAt
        recipient {
          fullname
          address {
            street
            zipCode
            city
          }
          phone
          email
        }
        deliveryPoint {
          name
          contact {
            fullname
            address {
              street
              zipCode
              city
            }
            phone
          }
        }
        pickupPoint {
          name
          contact {
            fullname
            address {
              street
              zipCode
              city
            }
            phone
          }
        }
        deliveryAtHome
        shippingCost
        history {
          status
          _ts
          _id
        }
      }
      before
    	after
  }
}`;



export default function MyBoxitSending() {
  return (
    <>
      <MyBoxitListHeader />
      <CheckoutInfoHeader />
      <MyBoxitList query={MY_BOXES} />
    </>
  );
}
