import React, { useState } from 'react';
import { Flex, Box, Text, Heading } from 'rebass';
import { Map } from '../Map';
import { Hide } from '../hide';
import { useTranslation } from 'react-i18next';

const BASE_COST = 3.25;
const DELIVERY_AT_HOME_COST = 5.0;

export default function SelectPickupPoint({
  recipient,
  onMarkerSelected,
  ...props
}) {
  const {
    address: { street, zipCode, city },
    phone,
  } = recipient;
  const recipientAddress = `${street}, ${zipCode}, ${city}`;

  const [boxitData, setBoxitData] = useState({
    pickup: {
      address: recipientAddress,
      phone: phone,
      additionalCost: DELIVERY_AT_HOME_COST,
    },
  });
  const [totalCost, setTotalCost] = useState(BASE_COST);

  const calculateShippingCost = additionalCost => {
    setTotalCost((BASE_COST + parseFloat(additionalCost)).toFixed(2));
  };

  const { t } = useTranslation();

  return (
    <Box width={1}>
      <Flex width={1} flexWrap="wrap" marginTop="-10px">
        <Box width={[1, '57%', '57%']} marginTop="auto">
          <Heading
            fontSize={['18px', '20px', '25px']}
            fontWeight="normal"
            lineHeight="1.04"
            letterSpacing="0.2px"
            color="blues.peacock"
            mt={['15px', '45px', '45px']}
            mb="7px"
            mr={[0, '100px', '100px']}
          >
            {t('my_boxit.create_box_modal.select_pickup_point.header')}
          </Heading>
          <Flex
            flexDirection={['column', 'row', 'row']}
            marginTop="28px"
            marginBottom="16px"
          >
            <Text fontSize="15px" letterSpacing="0.12" color="blues.peacock">
              {t(
                'my_boxit.create_box_modal.select_pickup_point.recipient_address'
              )}
            </Text>
            <Text
              fontSize={['14px', '12px', '15px']}
              letterSpacing="0.12"
              fontWeight="bold"
              color="blues.peacock"
              ml={[0, '5px', '5px']}
            >
              {recipientAddress}
            </Text>
          </Flex>
        </Box>
        <Box flex={1} ml={[0, '20px', '20px']}>
          <Flex>
            <Text
              fontSize={['12px', '17px', '17px']}
              letterSpacing="0.14"
              color="blues.peacock"
              marginTop="auto"
            >
              {t(
                'my_boxit.create_box_modal.select_pickup_point.shipping_costs'
              )}
            </Text>
            <Text
              flex={1}
              fontSize={['47px', '57px', '57px']}
              letterSpacing="0.12"
              fontWeight="bold"
              color="blues.peacock"
              textAlign="right"
              marginBottom="-20px"
            >
              {t('euro')}
              {totalCost}
            </Text>
          </Flex>
          <Text
            fontSize="10px"
            lineHeight="14px"
            letterSpacing="0"
            color="blues.peacock"
            marginTop="6px"
          >
            {t(
              'my_boxit.create_box_modal.select_pickup_point.shipping_charges'
            )}
          </Text>
          <Text
            fontSize="16px"
            lineHeight="16px"
            letterSpacing="1px"
            color="blues.peacock"
            fontWeight="bold"
            textAlign="center"
            marginTop="30px"
          >
            {t('my_boxit.create_box_modal.select_pickup_point.pickup_points')}
          </Text>
        </Box>
      </Flex>

      <Hide mobile>
        <Map
          id="pickup-point"
          height="280px"
          splitAt="57%"
          showSearchBox={false}
          boxitData={boxitData}
          autoLoadPickupPoints={true}
          showShippingCosts={true}
          updateShippingCost={calculateShippingCost}
          onMarkerSelected={onMarkerSelected}
        />
      </Hide>
      <Hide tablet desktop>
        <Map
          id="pickup-point"
          height="280px"
          fullWidthMap={true}
          isMobile={true}
          showSearchBox={false}
          boxitData={boxitData}
          autoLoadPickupPoints={true}
          showShippingCosts={true}
          updateShippingCost={calculateShippingCost}
          onMarkerSelected={onMarkerSelected}
        />
      </Hide>
    </Box>
  );
}
