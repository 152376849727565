import React from 'react';
import { navigate } from 'gatsby';
import { useIdentityContext } from 'react-netlify-identity';
import i18n from '../i18n';

const PrivateRoute = props => {
  const identity = useIdentityContext();
  const { isLoggedIn } = identity;

  if (!identity.user || !isLoggedIn) {
    navigate((i18n.language === 'en')? `${'/'+i18n.language}/sign_in`: '/sign_in');
    return null;
  }
  const { component: Component, ...rest } = props;
  return <Component {...rest} />;
};
export default PrivateRoute;
