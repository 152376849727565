module.exports = {
  en: {
    path: 'en',
    locale: 'English',
  },
  el: {
    path: 'el',
    local: 'Greek',
    default: true,
  },
};
