import React, { useState } from 'react';
import { useIdentityContext } from 'react-netlify-identity';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql  } from 'apollo-boost';
import { Heading, Box } from 'rebass';
import { useTranslation } from 'react-i18next';
import ScaleLoader from "react-spinners/ScaleLoader";

import Form from '../Form';
import AccountDetailsForm from '../AccountDetailsForm';
import AccountCorpDetailsForm from '../AccountCorpDetailsForm';
import { useGlobalContext } from "../../GlobalState";


const UPDATE_ACCOUNT = gql`
  mutation UpdateAccount(
    $accountId: ID!
    $email: String!
    $password: String!
    $fullname: String!
    $street: String!
    $zipCode: String!
    $city: String!
    $phone: String!
    $termsAccepted: Boolean!
  ) {
    updateAccount(id: $accountId
      data: {
        username: $email
        password: $password
        contact: {
          fullname: $fullname
          address: { street: $street, zipCode: $zipCode, city: $city }
          phone: $phone
          email: $email
        }
        termsAccepted: $termsAccepted
      }
    ) {
      _id
      username
      password
      termsAccepted
      contact {
        fullname
        address {
          street
          zipCode
          city
        }
        phone
        email
      }
      companyInfo{
        companyName
        vat 
        taxOffice
        POBox
      }
    }
  }
`;


const ACCOUNT_DETAILS = gql`
  query AccountDetails($accountId: ID!) {
    findAccountByID(id: $accountId) {
      _id
      username
      password
      termsAccepted
      contact {
        fullname
        address {
          street
          zipCode
          city
        }
        phone
        email
      }
      companyInfo{
        companyName
        vat 
        taxOffice
        POBox
      }
    }
  }
`;

export default function MyBoxitAccountDetails() {
  const [alertType, setAlertType] = useState(null);
  const [alertMsg, setAlertMsg] = useState(null);
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [credentials, setCredentials] = useState({});
  const identity = useIdentityContext();
  const { user } = useGlobalContext();
  const { updateUser } = identity;
  const isCorpUser = (user.type === "CORP");
  const userId = user._id
  const { loading, error, data } = useQuery(ACCOUNT_DETAILS, {
    variables: { accountId: userId},
  });

  const [updateAccount] = useMutation(UPDATE_ACCOUNT, {
    onError: error => {
      setLoadingIndicator(false)
      setAlertType('error')
      setAlertMsg(t('validation.failed_to_create_account'));
      const errMap = {
        'instance not unique': t('validation.already_registered_email'),
      };
      error.graphQLErrors.map(err => {
        const msg = errMap[err.extensions.code];
        if (msg) {
          setAlertMsg(msg);
        }
        return err;
      });
    },
    onCompleted: async (data) => {
      const { updateAccount:{username, password}} = data
      try{
        const res = await updateUser({email: username, password:password})
        console.log(res);
        setAlertType('success')
        setAlertMsg(t('validation.account_updated'));
      }catch(e){
        setAlertType('error')
        setAlertMsg(t('validation.account_not_updated'));
        setLoadingIndicator(false)
      }
      setLoadingIndicator(false)
    },
  });


  const handleSubmit = data => {
    //event.preventDefault();
    data.accountId = identity.user.user_metadata.aid;
    data.termsAccepted = true;
    delete data.confirmPassword;
    setLoadingIndicator(true);
    setAlertMsg(null);
    // setCredentials({email: data.email, password: data.password})
    console.log('data',data)
    updateAccount({
      variables: data,
    });
  };

  const { t } = useTranslation();

  if(loading){
    return (
    <>
      <Heading px="10%" fontSize="25px" lineHeight="1.04" color="blues.peacock">
        {t('my_boxit.account_details.header')}
      </Heading>
      <Box mt="30px" textAlign="center"> 
        <ScaleLoader
          css={{}}
          size={40}
          color={"#01549b"}
          loading={true}
        />
      </Box>
    </>);
  }

  if(error){
    return (<Heading px="10%" fontSize="25px" lineHeight="1.04" color="blues.peacock">
    {t('my_boxit.account_details.header')}
  </Heading>)
  }

  return (
    <>
      <Heading px="10%" fontSize="25px" lineHeight="1.04" color="blues.peacock">
        {t('my_boxit.account_details.header')}
      </Heading>
      <Form onSubmit={handleSubmit}>
        {isCorpUser?
        <AccountCorpDetailsForm
          account={data && data.findAccountByID}
          padding="10%"
          alertType={alertType}
          message={alertMsg}
          onSubmit={handleSubmit}
          isAccountPage={true}
          loadingIndicator={loadingIndicator}
      /> : 
        <AccountDetailsForm
          account={data && data.findAccountByID}
          padding="10%"
          alertType={alertType}
          message={alertMsg}
          onSubmit={handleSubmit}
          isAccountPage={true}
          loadingIndicator={loadingIndicator}
        />
      }
      </Form>
    </>
  );
}
