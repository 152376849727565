import React from 'react';
import { Flex, Box, Button, Text, Heading } from 'rebass';
import { gql } from 'apollo-boost';
import { useTranslation } from 'react-i18next';

import MyBoxitList from '../MyBoxitList';
import { datetime } from '../../utils';

const MyBoxitListHeader = () => {
  const { t } = useTranslation();

  return (
    <Flex width={1} flexDirection="row">
      <Box width={[1, 1 / 2, 1 / 2]}>
        <Heading fontSize="25px" lineHeight="1.04" color="blues.peacock">
          {t('my_boxit.summary.latest_summary')}
        </Heading>
        <Text>{datetime()}</Text>
      </Box>
      {/* <Box ml="auto">
      <input />
      <Button>SEARCH</Button>
    </Box> */}
    </Flex>
  );
};

export const MY_BOXES = gql`
query FindAccountBoxes($accountId: ID!, $cursorId: String) 
{
  boxesByAccountIdNotDeletedAsc(accountId: $accountId, _size: 5, _cursor: $cursorId)
  {
    data {
        _id
        _ts
        size
        status
        createdAt
        recipient {
          fullname
          address {
            street
            zipCode
            city
          }
          phone
          email
        }
        deliveryPoint {
          name
          contact {
            fullname
            address {
              street
              zipCode
              city
            }
            phone
          }
        }
        pickupPoint {
          name
          contact {
            fullname
            address {
              street
              zipCode
              city
            }
            phone
          }
        }
        deliveryAtHome
        shippingCost
        history {
          status
          _ts
          _id
        }
      }
      before
    	after
  }
}`

export default function MyBoxitSummary() {
  return (
    <>
      <MyBoxitListHeader />
      <MyBoxitList query={MY_BOXES} showCheckout={false} />
    </>
  );
}
