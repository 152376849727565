import React from 'react';
import { Flex, Box } from 'rebass';
import { navigate } from 'gatsby';
import { useIdentityContext } from 'react-netlify-identity';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

import { Layout } from '../../layout';
import { Hide } from '../../components/hide';
import SEO from '../../components/seo';
import { BoxitModalProvider } from '../../components/Modal';
import { HeaderCompact, NavLink } from '../../components/Header';
import Container from '../../components/container';
import { HeroCardLocateBox, HeroCardFindAegean } from '../../components/Hero';
import { client } from '../../graphql/client';
import { useGlobalContext } from "../../GlobalState";
import Alert from '../../components/Alert';


const MyBoxitLayout = ({ subtitle, children, ...props }) => {
  const { logoutUser } = useIdentityContext();
  const { removeUser, user } = useGlobalContext();
  const { t } = useTranslation();

  return (
    <Layout {...props}>
      <SEO title="My WEBOXIT" keywords={['weboxit', 'boxit']} />
      <BoxitModalProvider>
        <HeaderCompact title="My WEBOXIT" subtitle={subtitle}>
          <NavLink to="/my_boxit/summary" activeClassName="header-active-link">
            {t('my_boxit.menu.summary')}
          </NavLink>
          <NavLink to="/my_boxit/sending" activeClassName="header-active-link">
            {t('my_boxit.menu.sending')}
          </NavLink>
          {/* <NavLink to="/my_boxit/incoming" activeClassName="header-active-link">
          INCOMING
        </NavLink>
        <NavLink to="/my_boxit/returning" activeClassName="header-active-link">
          RETURNING
        </NavLink> */}
          <NavLink to="/my_boxit/account" activeClassName="header-active-link">
            {t('my_boxit.menu.account_details')}
          </NavLink>
          <NavLink
            as="a"
            href="/"
            onClick={async (event) => {
              event.preventDefault();
              await logoutUser();
              client.clearStore();
              removeUser();
              navigate((i18n.language === 'en')? `${'/'+i18n.language}/sign_in`: '/sign_in');
            }}
          >
            {t('my_boxit.menu.logout')}
          </NavLink>
        </HeaderCompact>
        <Container>
          <Box mt="40px">
            { (user !== null && !user.isEnabled)? <Box pb={10}>
              <Alert message={t('my_boxit.disabledAccount')} /> 
            </Box>: null}
            <Hide mobile tablet>
              <Flex>
                {/* Sidebar */}
                <Box width={1 / 3.18} mr="15px">
                  <Flex flexDirection="column">
                    <Box mb="24px">
                      <HeroCardLocateBox />
                    </Box>
                    <Box mb="24px">
                      <HeroCardFindAegean />
                    </Box>
                  </Flex>
                </Box>
                {children}
              </Flex>
            </Hide>
            <Hide desktop>
              <Box>{children}</Box>
            </Hide>
          </Box>
        </Container>
      </BoxitModalProvider>
    </Layout>
  );
};

export default MyBoxitLayout;
